import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby"

import QRCode from "react-qr-code"
import { StringParam, useQueryParam } from "use-query-params"

import DownloadSection from "../components/download"
import Layout from "../components/layouts"
import Seo from "../components/seo"

import { LANG_IT } from "../utils/localization"
import { SiteInformation } from "../utils/site"

const DoodlePage: React.FC<PageProps<SiteInformation>> = ({ data, path }) => {
  const [doodleId] = useQueryParam("id", StringParam)
  const showDoodle = doodleId && doodleId.length === 6

  return (
    <Layout lang={LANG_IT}>
      <Helmet>
        <link
          rel="alternate"
          hrefLang={"x-default"}
          href={`${data.site.siteMetadata.siteUrl}/doodle/`}
        />
        <link
          rel="alternate"
          hrefLang={LANG_IT}
          href={`${data.site.siteMetadata.siteUrl}/it/doodle/`}
        />
      </Helmet>
      <Seo
        title={showDoodle ? doodleId : "Doodle"}
        lang={LANG_IT}
        description="Usa la fotocamera del telefono per scansionare questo codice e caricare il doodle nella tua app."
        path={path}
      />
      <section id="hero">
        <div className="container">
          {showDoodle ? (
            <div className="row">
              <div className="justify-content-center align-self-center text-center col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <QRCode
                  value={`${data.site.siteMetadata.siteUrl}/it/doodle/${doodleId}`}
                />
              </div>
              <div className="col-md-7 content-box">
                <span>
                  Codice: <b>{doodleId}</b>
                </span>
                <h1>
                  Usa la <b>fotocamera del telefono</b> per scansionare questo
                  codice e caricare il doodle nella tua app.
                </h1>
                <p>
                  Quando gli utenti aprono un doodle in un dispositivo in cui
                  l'app non è installata, si apre la pagina dello store
                  dell'app. Dopo che gli utenti hanno installato e aperto l'app,
                  avranno accesso all'area di disegno.
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      <DownloadSection lang={LANG_IT} />
    </Layout>
  )
}

export default DoodlePage

export const query = graphql`
  query {
    site {
      ...SiteInformation
    }
  }
`
